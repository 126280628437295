import React from 'react';
import { Popover, OverlayTrigger } from "react-bootstrap"

class ProfilePopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            focussed: true,
        }
    }


    render() {

        const popoverHoverFocus = (

            <Popover className="products-popover" id="profile-popoverp" >
                <div>
                    <div className="row contentpanel">
                        <div className="col-xs-11">
                            <span className="positive-change"> Disclaimer </span>
                            : You are requested to fill in the mentioned details for your Indiabulls Securities Limited. account & also note that the information provided by you would be updated in our records for all future references.
                        </div>
                        <div className="col-xs-1">
                            <img src='assets/svg/close.svg' className="closebtn" data-toggle="tooltip" title="Close" onClick={() => { this.refs.productsPopover.hide() }} alt="close"/>
                        </div>
                    </div>
                </div>
            </Popover>
        );

        return (
            <OverlayTrigger ref="productsPopover" trigger={['click']} placement="bottom" container={this.props.parentRef} containerPadding={25} overlay={popoverHoverFocus} rootClose>
                <div className="watchlistoptions text-right">
                    <img src="assets/svg/info_selected.svg" className="info-icon" onerror="this.src='assets/images/logo.png'" alt="logo"/>
                </div>
            </OverlayTrigger>
        )
    }
}

export default ProfilePopup;