import React from "react";
import { setCookie, getCookie } from "../../common/utils";

class PaymentAtomRedirect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: getCookie('status'),
      msg: getCookie('msg').replaceAll('+', ' '),
      transID: getCookie('transID'),
    };
  }
  getCookie(name) {
    var re = new RegExp(name + "=([^;]+)");
    var value = re.exec(document.cookie);
    return value != null ? unescape(value[1]) : null;
  }

  render() {
    return (
      <div className="payment-bg login-responsive">
        <div className="row contentpanel">
          <img
            src="assets/images/Indiabulls-Securities-Logo-2.jpg"
            className="header-logo"
            alt="Indiabulls"
            style={{ marginLeft: "8px",padding: '10px 0 10px 0' }}
          />
        </div>
        <div className="demat-heading">
          <span>Status</span>
        </div>
        <div className="status-message">
            {this.state.status == "SUCCESS" && <p>Your Demat payment was successful. Please note that it may take 1-2 days for the payment to reflect in the system.</p>}
            {this.state.status === 'FAILED' && <p>Your payment has failed.<br/>Reason: {this.status.msg}</p>}
            <p>Your transaction ID is {this.state.transID}</p>
        </div>
      </div>
    );
  }
}
export default PaymentAtomRedirect;
