import React, { Component } from 'react';
import OrderbookBaseWidget from '../base/OrderbookBaseWidget';
import ToggleSwitch from '../../controls/ToggleSwitch';
import SearchInput from '../../controls/SearchInput';
import Dropdown from '../../controls/Dropdown';
import InputPlusMinus from '../../controls/InputPlusMinus';
import { commonDropDownType } from "../../common/constants";
import DatePicker from '../../controls/DatePicker';
import SEPOrderConfirmation from './SEPOrderConfirmation';


// const progressindicator = (
//     <div className="widget-progress-holder">
//         <svg class="circular-progress" viewBox="25 25 50 50">
//             <circle class="circular-progress_path" cx="50" cy="50" r="20"
//                 fill="none" stroke-width="2" stroke-miterlimit="10" />
//         </svg>
//     </div>
// );

class SEP extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [1, 2, 3, 4],
            currentpage: 'sep'
        }
    }

    onConfirmOrderClicked() {
        this.setState({ currentpage: 'confirm_order' });
    }

    onBackButtonClicked() {
        this.setState({ currentpage: 'sep' });
    }

    onSubmitClicked() {
        this.setState({ currentpage: 'order_success' })
    }

    render() {
        const styles={style:"gainIndicesDrop"}
        return (
            <OrderbookBaseWidget title="START SEP" hideexpander={true}>
                <div className={this.state.currentpage === "confirm_order" || this.state.currentpage === "order_success" ? "fillheight sep-popup-opacity" : "fillheight"}  >
                    <div className="row contentpanel">
                        <div className="sep-header">
                            <div className="col-xs-2">
                                <div className="widget-title-component sep-toggle order-toggle">
                                    <ToggleSwitch button1="NSE" button2="BSE" />
                                </div>
                            </div>
                            <div className="col-xs-5">
                                <SearchInput />
                            </div>
                        </div>
                        <div className="sep-content">
                            <div className="col-xs-8">
                                <div className="row sep-row">
                                    <div className="col-xs-3 order-radio-label">
                                        Select frequency
                                        <div className="sep-dropdown">
                                            <Dropdown
                                                dropdownType={commonDropDownType.DEFAULT}
                                                alignDefault={true}
                                                list={[{ name: 'WEEKLY' }, { name: 'FORTNIGHTLY' }, { name: 'MONTHLY' }]}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xs-1"> </div>
                                    <div className="col-xs-3 order-radio-label">
                                        Amount
                                        <InputPlusMinus />
                                        <div className="sep-small-font"> Minimum Amount (500.00) </div>
                                    </div>
                                    <div className="col-xs-1"> </div>
                                    <div className="col-xs-3 order-radio-label">
                                        No. of Installments
                                        <div className="sep-dropdown">
                                            <Dropdown
                                                style={styles.style}
                                                dropdownType={commonDropDownType.DEFAULT}
                                                alignDefault={true}
                                                list={[{ name: '1' }, { name: '2' }, { name: '3' }, { name: '4' }, { name: '5' }, { name: '6' },
                                                { name: '7' }, { name: '8' }, { name: '9' }, { name: '10' }, { name: '11' }, { name: '12' }]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row sep-row">
                                    <div className="col-xs-3 order-radio-label">
                                        SEP start date
                                        <div className="sep-dropdown">
                                        </div>

                                    </div>
                                    <div className="col-xs-1 sep-datepicker">
                                        <DatePicker order={true} placement="right" />
                                    </div>
                                    <div className="col-xs-3 order-radio-label">
                                        SEP end date
                                        <div className="sep-dropdown">
                                        </div>
                                    </div>
                                </div>
                                <div className="row sep-row sep-btns">
                                    <button type="button" className="btn-footer order-place" onClick={this.onConfirmOrderClicked.bind(this)} >CONFIRM ORDER</button>
                                    <button type="button" className="btn-footer sep-cancel">CANCEL</button>
                                </div>
                            </div>
                            <div className="col-xs-4 sep-noteblock">
                                <div className="sep-notes">
                                    Notes:
                                    <div style={{ marginTop: 10 }} >
                                        To avail SEP facilities, please download NACH/ECS Mandate Registration form.
                                    </div>
                                    <div className="ipo-link">
                                        <a href="http://power.indiabulls.com/pdf/bank_mandate_sep.pdf">Click here to download</a> NACH/ECS Mandate form.
                                    </div>
                                    <ul>
                                        <li>Print the form on a single sheet of paper and fill up the required details mentioned in the form.</li>
                                        <li>Sign the form and submit it to the nearest Indiabulls Securities Limited branch.</li>
                                        <li>For any further assistance, please reach out to us at 022-61446300 or write to helpdesk@dhani.com</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.currentpage === 'confirm_order' ?
                    <SEPOrderConfirmation status="orderconfirm" backButtonClicked={this.onBackButtonClicked.bind(this)} submitClicked={this.onSubmitClicked.bind(this)} />
                    : ''
                }
                {this.state.currentpage === 'order_success' ?
                    <SEPOrderConfirmation status="ordersuccess" history={this.props.history} />
                    : ''
                }
            </OrderbookBaseWidget>
        )
    }

}

export default SEP;