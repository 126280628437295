import React, {Component} from "react";
import { connect } from "react-redux";
import Dropdown from "../../controls/Dropdown"
import { commonDropDownType, localStorageKeys } from "../../common/constants"
import { logout, getConfirmLimitAgainstHolding } from '../../actions/login/submitLogin'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import { setItemByKey, getTheme, getItemByKey } from '../../common/utils'
import Dialog from "../../controls/Dialog";

import { progressBar } from '../../controls/progress';
import { isEquityPermitted, isFnOPermitted, isCurrencyPermitted, isCommodityPermitted,isShubhPlanPermitted } from "../../common/permissions";
import {UserOptionsCommPermit, UserOptionsALLPermit} from "../../common/constants"

class UserOptions extends Component {

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.
        state = {
            error :false,
            confirmHead:'',
            confirmMsg:'',
            inProgress: false,
            user: {
                name: '',
            },
            data: (isEquityPermitted()===false && isFnOPermitted()===false && isCurrencyPermitted()===false && isCommodityPermitted()===true)?UserOptionsCommPermit:UserOptionsALLPermit,
            showDialog:false,
            togglebutton:getItemByKey(localStorageKeys.LIMITAGAINSTHOLDINGFLAG),
            // clickHere:false,
            limitHoldingMessage:''
        };
        this.onLogin = this.onLogin.bind(this);
    }

    componentWillReceiveProps(newprops) {
        this.onLogin(newprops.accountDetails);
    }

    componentWillMount() {
        this.onLogin(this.props.accountDetails);
        this. removePermission();

    }

    removePermission() 
    {
     
       if(isShubhPlanPermitted()===false)
       {
            this.setState({ data : this.state.data.filter((item) => item.name != 'My Trading Plan').map(({name}) => ({name})) });
       }
         
    }

    onLogin(accountDetails) {
        if (accountDetails) {
            this.setState({
                user: {
                    name: accountDetails.user,
                }
            });
        }
    }

    onDropDownItemClick(name) {
        if (name === "My Details") {
            this.props.history.push("/home/profile");
        }
        else if (name === "DP Holdings") {
            this.props.history.push("/home/dpholdings");

        } else if (name === "Fund Transfer") {
            this.props.history.push("/home/fundtransfer");
        }  
        else if (name === "My Trading Plan") {
            this.props.history.push("/home/mytradingplan");
        }
        else if (name === "Settings") {
            this.props.history.push("/home/settings");
        }
        else if (name === 'Logout') {
            this.props.logout();
        } 
        else if (name === 'Prefered Theme') {
            var th = getTheme();
            if (th === 'light') {
                setItemByKey(localStorageKeys.ACTIVE_THEME, 'dark');
            }
            else {
                setItemByKey(localStorageKeys.ACTIVE_THEME, 'light');
            }
            window.location.reload();
        }
    }

    ontoggleButton(name, togglebutton){
        this.setState({
                showDialog:true, 
                togglebutton:togglebutton,
                limitHoldingMessage:togglebutton=="1"?<p>I hereby authorise Indiabulls Securities Limited to deposit the securities as collateral with NSE Clearing Limited (NCL). <a onClick={this.showclickHerePopup.bind(this)} style={{textDecoration:'underline', cursor:'pointer'}}>Click here</a> for the details of the said consent/authorisation.</p>
                                :<p>I hereby revoke the authorisation given to Indiabulls Securities Limited to deposit the securities as collateral with NSE Clearing Limited (NCL).</p>
        });
    }
    closePopup() {
        this.setState({
            showDialog: false,
            error:false,
            togglebutton:getItemByKey(localStorageKeys.LIMITAGAINSTHOLDINGFLAG)
        });
    }

    confirmLimitAgainstHolding()
    {
        this.setState({
            inProgress: true, 
            error: false,
        });
        var requestData={
            request: {
                data: {
                    status:this.state.togglebutton
                }
            }
        }
        getConfirmLimitAgainstHolding(requestData, this.parseResponse.bind(this), this.parseError.bind(this));
    }

    parseResponse(responseData){
        this.setState({
            inProgress: false,
            togglebutton: getItemByKey(localStorageKeys.LIMITAGAINSTHOLDINGFLAG),
            error: true,
            confirmHead:responseData.response.data.data.Success?"Confirmation":"Message",
            confirmMsg:responseData.response.data.data.Success
                        ?
                        "Thank you for sending the request. You will recieve a confirmation email soon.":responseData.response.data.data.Error_Mesg
        });
    }

    parseError(error){
        this.setState({
            inProgress: false,
            error: true,
            confirmHead:'Error',
            confirmMsg:error.message,
            // confirmHead:'Confirmation',
            // confirmMsg:"Thank you for sending the request. You will recieve a confirmation email soon.",
            // confirmHead:'Message',
            // confirmMsg:"Your previous request is under process. Please make a new request once the existing request is processed.",
            togglebutton: getItemByKey(localStorageKeys.LIMITAGAINSTHOLDINGFLAG)
        });
    }

    // closeclickHerePopup(){
    //     this.setState({
    //         clickHere: false
    //     });
    // }

    showclickHerePopup()
    {
        window.open("https://www.dhanistocks.com/consent-page.html", "_blank");
    }

    


    render() {
        const header = <div className="useroption-header">
            <div className="user-img">
                <img src="assets/svg/Profile.svg" alt="profile"/>
            </div>
            <span className="user-id span-wrap">{this.state.user.name}</span>
        </div>;
        const styles = {style:"usropt-drdown"}
        return (
            <div className="col-12 col-lg-12 col-md-12 nopadding useroption-parent lessPadding">
                <Dropdown dropdownType={commonDropDownType.CUSTOM} 
                customeHeader={header} list={this.state.data} style={styles.style} position={'header-popper'}
                onSelectionChanged={this.onDropDownItemClick.bind(this)}
                selectToggle={this.state.togglebutton}
                ontoggleButton={this.ontoggleButton.bind(this)}/>
                {this.state.showDialog?
                     (this.state.inProgress ? (progressBar()) :
                        (this.state.error ? (<Dialog header={this.state.confirmHead} message={this.state.confirmMsg} negativeButtonName="OK" negativeClick={this.closePopup.bind(this)} />) :
                    <Dialog header="Review & Confirm" 
                    message={this.state.limitHoldingMessage}
                    positiveButtonName="Confirm"
                    negativeButtonName="Cancel"
                    positiveClick={this.confirmLimitAgainstHolding.bind(this)}
                    negativeClick={this.closePopup.bind(this)} />))
                :null}
                {/* {this.state.clickHere?
                    <Dialog header="Consent/Authorisation" 
                    message={
                    <div>
                        <p>This is in reference to the demat account held with Dhani Stocks Limited. I intend to trade frequently in Capital Market /Futures and Options / Currency Derivative Segment and would like to adjust the securities lying in my demat account against the margin requirement in Capital Market and on open position in Futures and Options / Currency Derivative Segment.</p>
                        <p>In this regards, I intend to keep collateral in the form of securities towards margin requirements and other obligations for the transactions executed/ to be executed through you on the stock exchanges.   </p>
                        <p>I understand that as a broker, you are required to deposit collateral with the Stock Exchanges/Clearing Corporation/Clearing House towards margin requirements for the trades done/to be done. I hereby give my unequivocal consent to pledge securities,  lying in my demat account or provided to you as collateral by me, with the Stock Exchanges/Clearing Corporation/Clearing House for meeting the margin requirements and other obligations in my account. You may release such securities on my request. </p>
                        <div>In this regards, I also confirm and authorise you for the followings:
                            <ul>
                                <li>
                                    The securities that I will intend to provide you as collateral (�Said Securities�) are in existence, owned by me and are and shall be free from any charge, lien or encumbrance, whether prior or otherwise
                                </li>
                                <li>
                                    that the Said Securities will be subject to the creation of pledge in favour of or for the benefit of NSE Clearing Limited (NCL) and further that the  Securities over which pledge may be created in future would be in existence and owned by me at the time of creation of such pledge and that the Said Securities to be given in future as security to NCL would likewise be unencumbered, absolute and disposable property of my  
                                </li>
                                <li>
                                    that the Clearing Member is authorized to do all such acts and things, sign such documents and pay and incur any such costs, debts and expenses as may be necessary under this Deed of Pledge and the same shall be subject to terms and conditions as contained herein
                                </li>
                                <li>
                                    that I agrees that the Said Securities shall be subject to the first priority and lien in favour of NCL to secure, the my obligations and that the my rights or interests of with respect to the Said Securities shall be subject and subordinate to the rights, claims and interests of NCL in respect of the Said Securities
                                </li>
                                <li>
                                    that NCL may invoke the pledge without any reference to or my permission and upon receipt of the Said Securities, NCL may utilize the proceeds in meeting my obligations in such manner as it may deem fit and that such invocation of pledge will be final and irrevocable against the Clearing Member and me
                                </li>
                                <li>
                                    I shall not make any claims or demands for refund or any reimbursement in relation to the Said Securities.
                                </li>
                            </ul>
                        </div>
                    </div>
                    }
                    negativeButtonName="Close"
                    negativeClick={this.closeclickHerePopup.bind(this)} />
                :null} */}
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        accountDetails: state.loginStatus.accountDetails
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        logout: () => logout(dispatch)
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserOptions)); 