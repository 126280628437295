import React from "react";
import { setCookie, getCookie } from "../../common/utils";
import { UrlExtensions, BaseUrl } from "../../common/constants";
import { withRouter } from "react-router-dom";
import CryptoJS from "crypto-js";

class PaymentAtom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dematAccNo: "",
      error: "",
      captchValue: "",
      loading: false,
    };
  }

  componentDidMount() {
    if (getCookie("DATA_PARAM") != 'null') {
      this.setState({ ...this.state, dematAccNo: getCookie("DATA_PARAM") });
    }
    setCookie("REDIRECT_TO", "", 0);
  }

  encryptParams(params) {
      const encryptedParams = CryptoJS.AES.encrypt(
        JSON.stringify(params),
        "secKey"
      ).toString();
      return encodeURIComponent(encryptedParams);
    }

  getPaymentDetails() {
    this.setState({ ...this.state, error: "" }, () => {
      if (this.state.dematAccNo == "") {
          this.setState({ ...this.state, error: "Enter a valid DP ID" })
        return;
      }
   if (this.state.captchValue == "") {
            this.setState({ ...this.state, error: "Enter a valid Captcha" })
          return;
        }
      var requestData = {
        dematAccountNumber: this.state.dematAccNo,
        captchaCode: this.state.captchValue
      };
      var url = BaseUrl + UrlExtensions.GET_DEMAT_DETAILS;
      this.setState({ ...this.state, loading: true });
      fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => {
          if (response.status == 200) return response.json();
          else {
            throw new Error(response.statusText);
          }
        })
        .then((response) => {
          if (response.statusCode == 200) {
              let urlParams ={
                                 "clientName": response.ClientDetails.clientName,
                                 "ibRefNo": response.ClientDetails.ibRefNo,
                                 "dematAccNo": response.ClientDetails.dematAccNo,
                                 "totalAmount": response.ClientDetails.totalAmount,
                                 "serviceCharge": response.ClientDetails.serviceCharge,
                                 "gstPercentage": response.ClientDetails.gstPerc,
                                 "totalPayAmount": response.ClientDetails.totalPayAmt,
                                 "mobile": response.ClientDetails.mobile,
                                 "email": response.ClientDetails.email,
                               }
              let encryptedParams = this.encryptParams(urlParams);
              this.props.history.push(`/DematPaymentDetails?params=${encryptedParams}`);
          } else {
              this.refreshCaptcha();
              this.setState({ ...this.state, error: response.message || 'Something went wrong', captchValue: '', loading: false });
          }
        })
        .catch((error)=> {
            console.log(error);
            this.refreshCaptcha();
            this.setState({ ...this.state, error:  'Something went wrong', captchValue: '', loading: false });
        })
    });
  }

  setDematNo(value) {
    this.setState({ ...this.state, dematAccNo: value });
  }
  setCaptchValue(value) {
    this.setState({ ...this.state, captchValue: value });
  }
  refreshCaptcha(){
    document.getElementById('captcha-img').src = 'generateCaptcha?d='+new Date()*1;
  }

  render() {
    return (
      <div className="payment-bg login-responsive">
        {this.state.loading && (
          <div className="loader-payments">
            <img src="assets/images/loader.gif" alt="loader" />
          </div>
        )}
        <div className="row contentpanel">
          <img
            src="assets/images/Indiabulls-Securities-Logo-2.jpg"
            className="header-logo"
            alt="Indiabulls"
            style={{ marginLeft: "8px",padding: '10px 0 10px 0' }}
          />
        </div>
        <div className="demat-heading">
          <span>Enter Demat Account Number</span>
        </div>
        <div className="payment-form container">
          <div className="demat-number row">
            <span className="demat-text col-12 col-sm-6">
              Demat Account Number :
            </span>
            <div className="number-field col-12 col-sm-6">
              <input
                autoComplete="none"
                className="payment_formcontrol"
                value={this.state.dematAccNo}
                onChange={(e) => this.setDematNo(e.target.value)}
                type="text"
                name="dematAccNo"
                placeholder="Demat Account Number"
              ></input>
            </div>
          </div>
          <div className="demat-number row">
            <span className="demat-text col-12 col-sm-6">Captcha :</span>
            <div className="number-field col-12 col-sm-6">
              <input
                autoComplete="none"
                className="payment_formcontrol"
                onChange={(e) => this.setCaptchValue(e.target.value)}
                type="text"
                value={this.state.captchValue}
                name="captchValue"
              ></input>
              <div className="captcha-text">
                <img src="/generateCaptcha" id="captcha-img" width='200' height='50' alt="" />
                <button className="captcha-refresh" onClick={this.refreshCaptcha.bind(this)}>Refresh</button>
              </div>
            </div>
          </div>
          <div className="error-panel">{this.state.error != "" ? (
               <span className="error-text">{this.state.error}</span>
             ) : (
               "")}
          </div>
          <div className="button-panel">
            <div className="empty-panel"></div>
            <button
              className="payment-button"
              onClick={this.getPaymentDetails.bind(this)}
              disabled={this.state.loading}
            >
              NEXT
            </button>
          </div>
        </div>
        <div className="payment-notes">
          <ul>
            Notes:
            <li>
              Please use latest Chrome, Firefox, & IE browsers as the website is
              not compatible with IE10 and below.
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
export default withRouter(PaymentAtom);
