import React from "react";
import IndicesQuote from '../../../components/home/IndicesQuote'
import SymbolSearch from '../../../components/home/SymbolSearch'
import UserOptions from '../../../components/home/UserOptions'
import CartIcon from '../../../components/home/CartIcon'
import CalculatorIcon from '../../../components/home/CalculatorIcon'
import NotificationsIcon from '../../../components/home/NotificationsIcon'
import { ModuleKeys, symbolSearchType } from '../../../common/constants'

class Header extends React.Component {

    render() {
        return (
            <header>
                <div id="pageHeader">
                <div className="row customRow">
                    <div className="col-5 col-sm-5 col-lg-5 col-md-5 pageheader-componentholder">
                  
                        <div className="row customRow">
                        <div className="col-3 col-sm-3 col-lg-3 col-md-3 pageheader-componentholder outer">
                            <div className="home-logo-parent">
                                <img src="assets/svg/indiabulls-logo.svg" className="img-responsive home-logo" alt="Logo" />
                            </div>
                        </div>
                        <div className="col-9 col-sm-9 col-lg-9 col-md-9 pageheader-componentholder outer">
                            <SymbolSearch searchType={ symbolSearchType.header } history={ this.props.history } />
                        </div>
                    </div>
                   
                    </div>
                    <div className="col-7 col-sm-7 col-lg-7 col-md-7 pageheader-componentholder">
                    
                    <div className="row customRow">
                        <div className="col-7 col-sm-7 col-lg-7 col-md-7 pageheader-componentholder">
                        
                        <div className="row customRow">
                            <div className="col-6 col-sm-6 col-lg-6 col-md-6 pageheader-componentholder outer">
                                <IndicesQuote index={ModuleKeys.SENSEX} maxSelect={3} tag="SensexIndices" />
                            </div>
                            <div className="col-6 col-sm-6 col-lg-6 col-md-6 pageheader-componentholder outer">
                                <IndicesQuote index={ModuleKeys.NIFTY} maxSelect={3} tag="NiftyIndices" />
                            </div>
                            </div>
                        
                        </div>
                        <div className="col-2 col-sm-2 col-lg-2 col-md-2 pageheader-componentholder outer">
                            <UserOptions />
                        </div>
                        <div className="col-3 col-sm-3 col-lg-3 col-md-3 pageheader-componentholder">
                        
                        <div className="row customRow">
                            <div className="col-4 col-sm-4 col-lg-4 col-md-4 pageheader-componentholder outer">
                                <CartIcon history={ this.props.history } />
                            </div>
                            <div className="col-4 col-sm-4 col-lg-4 col-md-4 pageheader-componentholder outer">
                                <CalculatorIcon />
                            </div>
                            <div className="col-4 col-sm-4 col-lg-4 col-md-4 pageheader-componentholder outer">
                                <NotificationsIcon history={ this.props.history } />
                            </div>
                            </div>
                           
                        </div>
                        </div>
                        
                    </div>

                </div>
               
                </div>
            </header>
        );
    }
}

export default Header;